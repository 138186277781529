import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import { Row, Container } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import Glasses from '../../assets/glasses.svg';
import { black } from '../../utils/nounBgColors';
import classes from './EnterPage.module.css';
import EnterActivityUpsidenounTitle  from '../../components/EnterActivitySunounTitle';
import EnterUpsidenounBtn  from '../../components/EnterSunounBtn';

const EnterPage  : React.FC<{}> = props =>  {

  const [rotate, setRotate] = useState(false);

  const glasses = (
    <div className={rotate ? classes.rotate : classes.unrotate}>
      <Image src={Glasses} alt={'loading upsidenoun-Enter'} fluid/>
    </div>
  );

  const enterButton = (
    <div className={classes.wrapper}>
      <EnterUpsidenounBtn setRotate={setRotate}/> 
    </div>
  );

  const enterAuction =  (
    <div className={classes.wrapper}>
      <EnterActivityUpsidenounTitle rotate={rotate}/> 
    </div>
  );

  return (
    <div style={{ backgroundColor: black }} className={classes.wrapper}>
      <Container fluid="xl">
        <Row>
          <Col lg={{ span: 6 }} className={classes.center}>
            {glasses}
          </Col>
          <Col lg={{ span: 6 }} className={classes.enterActivityCol}>   
            <div>   
              {enterAuction}
            </div>
            <div>
              {enterButton}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EnterPage;
