import Section from '../../layout/Section';
import { Col } from 'react-bootstrap';
import classes from './Documentation.module.css';
import Accordion from 'react-bootstrap/Accordion';
import Link from '../Link';
import Sunouns from '../Sunouns';
import { Trans } from '@lingui/macro';

const Documentation = () => {

  return (
    <Section fullWidth={false}>
      <Col lg={{ span: 10, offset: 1 }}>
        <div className={classes.headerWrapper}>
          <h1>
            <Trans>WTF?</Trans>
          </h1>
          <p className={classes.aboutText}>
            <Sunouns text='nouns' fontSize='22px' /> <Trans>are <Link text='Nouns' url='https://nouns.wtf/' leavesPage={true} /> of the 
              Underground, an experimental attempt to improve the formation of on-chain 
              pseudonymous (<span style={{color: 'var(--brand-sunrise-blue)'}}>su</span><span style={{color: 'var(--brand-dark-red)'}}>noun</span>omous)
              societies. <span style={{color: 'var(--brand-sunrise-blue)', fontSize: '20px', fontWeight: 'bold'}}>su</span> means 
              up in Italian which is what <Sunouns text='nouns' fontSize='20px'/> aspire to be.
              The avatars are Nouns that are rendered upsidedown introducing a new breed of on-chain NFT 
              composibility by reusing assets from the Nouns project and manipulating how those assets are
              rendered. <Sunouns text='nouns' fontSize='20px' /> are generated by calling the Nouns contract 
              and rendering them upsidedown.
            </Trans>
          </p>
          <p></p>
        </div>
        <Accordion flush>
          <Accordion.Item eventKey="0" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              <Trans>Summary</Trans>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  <Trans>The <Sunouns text='nouns' fontSize='20px'/> artwork is based on the Nouns artwork and in the 
                    <Link text='public domain' url="https://creativecommons.org/publicdomain/zero/1.0/"
                      leavesPage={true} />.
                  </Trans>
                </li>
                <li>
                  <Trans>One <Sunouns text='noun' fontSize='20px'/> is trustlessly auctioned every <span style={{color: 'var(--brand-sunrise-blue)' }}>4</span> hours and <span style={{color: 'var(--brand-sunrise-blue)' }}>2</span> minutes, forever.</Trans>
                </li>
                <li>
                  <Trans>100% of the <Sunouns text='nouns' fontSize='20px'/> auction proceeds are trustlessly sent to the <Sunouns text='nouns' fontSize='20px'/> treasury.</Trans>
                </li>
                <li>
                  <Trans>Settlement of one auction kicks off the next.</Trans>
                </li>
                <li>
                  <Trans>Artwork is generative and stored directly on-chain (not IPFS).</Trans>
                </li>
                <li>
                  <Trans>No explicit rules exist for attribute scarcity; all <Sunouns text='nouns' fontSize='20px'/> are equally rare.</Trans>
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              <Trans>Auctions</Trans>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                <Trans>
                  The <Sunouns text='nouns' fontSize='20px'/> auction contract acts as a self-sufficient generation and
                  distribution mechanism, auctioning one <Sunouns text='noun' fontSize='20px'/> every 
                  <span style={{color: 'var(--brand-sunrise-blue)' }}>4</span> hours and <span style={{color: 'var(--brand-sunrise-blue)' }}>2</span> minutes,
                  forever. 100% of auction proceeds (ETH) are automatically deposited in the <Sunouns text='nouns' fontSize='20px'/> treasury.
                  Each time an auction is settled, the settlement transaction will also cause a new <Sunouns text='noun' fontSize='20px'/> to 
                  be minted and a new <span style={{color: 'var(--brand-sunrise-blue)' }}>4</span> hours 
                  and <span style={{color: 'var(--brand-sunrise-blue)' }}>2</span> minute auction to begin.
                </Trans>
              </p>
              <p>
                <Trans>
                  While settlement is most heavily incentivized for the winning bidder, it can be triggered by anyone, 
                  allowing the system to trustlessly auction <Sunouns text='nouns' fontSize='20px'/> as long as Ethereum 
                  is operational and there are interested bidders.
                </Trans>
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              <Trans>The Underground</Trans>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                <Trans>
                  The Underground is a decentralized pseudonymous society of <Sunouns text='nouns' fontSize='20px'/> with the purpose of
                  improving how pseudonymous societies form and operate. The Underground and
                  the <Sunouns text='nouns' fontSize='20px'/> project were created 
                  by <a href="https://twitter.com/zunsunz" target="_blank" rel="noopener noreferrer" className={classes.sunouns}>zunsunz</a>, a
                  builder who wants improve the tools used to maintain pseudonymous identities.
                </Trans>
              </p>
              <p>
                <Trans>
                  Membership to the Underground is granted only to <Sunouns text='nouns' fontSize='20px'/>. 
                </Trans>
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              <Trans>Governance</Trans>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                <Trans>
                  The Underground is a experimental decentralized privacy-focussed society and the governmental processes are
                  are not yet fully developed. Each <Sunouns text='noun' fontSize='20px'/> is an irrevocable pseudonymous member
                  of the Underground and entitled to participate in all governance matters. But, the tools and means for the members 
                  to participate in decentralized private governance need to be developed.
                </Trans>
              </p>
              <p>
                <Trans>
                  Some matters require a legal entity to interact with the real world.
                  <span style={{color: 'var(--brand-sunrise-blue)'}}>Sunounomous Labs, LLC</span> was established to serve
                  these needs of the Underground. The <Sunouns text='nouns' fontSize='20px'/> treasury receives 100% of ETH
                  proceeds from daily auctions and will be used by the Underground to build tools used to maintain
                  pseudonymous identities.
                </Trans>
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              <Trans>Sunouns Traits</Trans>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                <Trans>
                  <Sunouns text='nouns' fontSize='20px'/> are generated randomly based Ethereum block 
                  hashes. There are no 'if' statements or other rules governing trait scarcity, which 
                  makes all <Sunouns text='nouns' fontSize='20px'/> equally rare. As of this 
                  writing, <Sunouns text='nouns' fontSize='20px'/> are made up of:
                </Trans>
              </p>
              <ul>
                <li>
                  <Trans>backgrounds (2) </Trans>
                </li>
                <li>
                  <Trans>bodies (30)</Trans>
                </li>
                <li>
                  <Trans>accessories (137) </Trans>
                </li>
                <li>
                  <Trans>heads (234) </Trans>
                </li>
                <li>
                  <Trans>glasses (21)</Trans>
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              <Trans>On-Chain Artwork</Trans>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                <Trans>
                  <Sunouns text='nouns' fontSize='20px'/> are stored directly on Ethereum and do not 
                  utilize pointers to other networks such as IPFS. This is possible 
                  because <Sunouns text='nouns' fontSize='20px'/> parts (which are really Noun parts)
                  are compressed and stored on-chain using a custom run-length 
                  encoding (<Link text='RLE' url='https://nouns.notion.site/Nouns-Protocol-32e4f0bf74fe433e927e2ea35e52a507#6bbc1162af90427bad2dda63a3b31254' leavesPage={true}/>), which is 
                  a form of lossless compression.
                </Trans>
              </p>
              <p>
                <Trans>
                  The compressed parts are efficiently converted into a single base64 encoded SVG
                  image on-chain. To accomplish this, each part is decoded into an intermediate
                  format before being converted into a series of SVG rects using batched, on-chain
                  string concatenation. Once the entire SVG has been generated, it is base64
                  encoded.
                </Trans>
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              <Trans>Seeder Contract</Trans>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                <Trans>
                  The <Sunouns text='nouns' fontSize='20px'/> Seeder contract is used to determine traits 
                  during the minting process. The seeder contract can be replaced to allow for future trait generation
                  algorithm upgrades. Currently, <Sunouns text='nouns' fontSize='20px'/> traits are determined using 
                  pseudo-random number generation:
                </Trans>
              </p>
              <code>keccak256(abi.encodePacked(blockhash(block.number - 1), nounId))</code>
              <br />
              <br />
              <p>
                <Trans>
                  Trait generation is not truly random. Traits can be predicted when minting a 
                  <Sunouns text='noun' fontSize='20px'/> on the pending block.
                </Trans>
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Section>
  );
};
export default Documentation;
