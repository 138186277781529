"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getContractAddressesForChainOrThrow = void 0;
const types_1 = require("./types");
const chainIdToAddresses = {
    [types_1.ChainId.Mainnet]: {
        nounsToken: '0x30c0420D0221CFEC35c7e3A215caf5C4e61198f7',
        nounsSeeder: '0xDbd99f38b7c739dA6Ca22965eec302aE0F3faCbb',
        nounsDescriptor: '0x0Cfdb3Ba1694c2bb2CFACB0339ad7b1Ae5932B63',
        nftDescriptor: '0x0BBAd8c947210ab6284699605ce2a61780958264',
        nounsAuctionHouse: '0x64287a87fCb6793c1AF0BeAd0C50d8419745521A',
        nounsAuctionHouseProxy: '0x8d4218f978B3BFe50a3dE949B5A477f09FE91855',
        nounsAuctionHouseProxyAdmin: '0xa27571233B44eD43f977f8555a5Ef3a04450b4bC',
        suNounsTreasury: '0x5258a8204765D5bd4632EbD2a333c58546b3dd25',
    },
    [types_1.ChainId.Rinkeby]: {
        nounsToken: '0x5F044ce2D370Ac59AAe327981076873157bC0636',
        nounsSeeder: '0xeF13d75Ce2663132B59b6E36082E8c5D0C0cad59',
        nounsDescriptor: '0x53cB482c73655D2287AE3282AD1395F82e6a402F',
        nftDescriptor: '0x1F28f148ef5f9BD182cCEfeAD4240A505C54dc9B',
        nounsAuctionHouse: '0x04f267c63F19B6D37796fD2a9D963915c88cabb4',
        nounsAuctionHouseProxy: '0x72E055dEA4827871f7E488525EB015868a4aC612',
        nounsAuctionHouseProxyAdmin: '0xb03582b70E2302BC94AD13363C2d7A7b00F962B7',
        suNounsTreasury: '0xFB7C2cCF4B01498676894451592603A6ad394E6e', // Gnosis Safe: sounouns rin:
    },
    [types_1.ChainId.Local]: {
        nounsToken: '0x36C02dA8a0983159322a80FFE9F24b1acfF8B570',
        nounsSeeder: '0x9d4454B023096f34B160D6B654540c56A1F81688',
        nounsDescriptor: '0xe7f1725e7734ce288f8367e1bb143e90bb3f0512',
        nftDescriptor: '0x5FbDB2315678afecb367f032d93F642f64180aa3',
        nounsAuctionHouse: '0x809d550fca64d94Bd9F66E60752A544199cfAC3D',
        nounsAuctionHouseProxy: '0x1291Be112d480055DaFd8a610b7d1e203891C274',
        nounsAuctionHouseProxyAdmin: '0x4c5859f0F772848b2D91F1D83E2Fe57935348029',
        suNounsTreasury: '0xbcd4042de499d14e55001ccbb24a551f3b954096', //  This is a Hardhat addr 10
    },
};
/**
 * Get addresses of contracts that have been deployed to the
 * Ethereum mainnet or a supported testnet. Throws if there are
 * no known contracts deployed on the corresponding chain.
 * @param chainId The desired chainId
 */
const getContractAddressesForChainOrThrow = (chainId) => {
    if (!chainIdToAddresses[chainId]) {
        throw new Error(`Unknown chain id (${chainId}). No known contracts have been deployed on this chain.`);
    }
    return chainIdToAddresses[chainId];
};
exports.getContractAddressesForChainOrThrow = getContractAddressesForChainOrThrow;
