import classes from './Banner.module.css';
import Section from '../../layout/Section';
import { Col } from 'react-bootstrap';
import calendarSunoun from '../../assets/calendar_upsidenoun.png';
import Noun from '../Noun';
import { Trans } from '@lingui/macro';

const Banner = () => {
  return (
    <Section fullWidth={false} className={classes.bannerSection} >
      <Col lg={6} >
        <div className={classes.wrapper}>
            <Trans>ONE</Trans>{' '}<span style={{fontSize: '7rem'}}><span style={{color: 'var(--brand-sunrise-blue)'}}>su</span>noun</span>,
            <br />
            <Trans>EVERY <span style={{color: 'var(--brand-sunrise-blue)'}}>4</span> HOURS <span style={{color: 'var(--brand-sunrise-blue)'}}>2</span> MINUTES,</Trans>
            <br />
            <Trans>FOREVER.</Trans>
        </div>
      </Col>
      <Col lg={6}>
        <div style={{ padding: '2rem' }}>
          <Noun imgPath={calendarSunoun} alt="noun" />
        </div>
      </Col>
    </Section>
  );
};

export default Banner;
