import classes from './EnterSunounBtn.module.css';
import Button from '@restart/ui/esm/Button';
import {useState} from 'react';
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import AuctionPage from '../../pages/Auction';

const EnterUpsidenounBtn: React.FC<{setRotate: React.Dispatch<React.SetStateAction<boolean>>}> = (props) => {

  const { setRotate } = props;

  //On shift+hover
  const [isHovering, setIsHovering] = useState(false);
  const [isHoveringText, setIsHoveringText] = useState('Enter the Underground');

  const handleMouseOver = (e: any) => {
    if(e.shiftKey){
      setIsHovering(true);
      setIsHoveringText('zunsunz');
    } else {
      setRotate(true);
    };
  };

  const handleMouseOut = () => {
    setIsHovering(false);
    setRotate(false);
    setIsHoveringText('Enter the Underground');
  }

  const zunsunz = (
    <div>
      <div className={classes.popUp}>
        <h2>Who is zunsunz?</h2>
        <p>
          Zunsunz is the creator of the sunouns and spokesperson for the sunouns pseudonymous society, aka the Underground.
          Zunsunz is on a mission to the build the tools that enable the Underground to privately communicate and organize in the real world!
        </p>
      </div>
    </div>
  );

  return (
    <>
    <div>         
      <Router>
        <Link to="/auction" target="_parent" className={classes.noLine}>
          <Route exact path="/auction" component={AuctionPage} />
          <Button
            onMouseOver={handleMouseOver} 
            onMouseOut={handleMouseOut}
            className={classes.enterBtnDesign}
          >
            {isHoveringText}
          </Button>
        </Link>
      </Router>
    </div>
    {isHovering && (
      <div>
        {zunsunz}
      </div> 
    )}
    </>
  );
};

export default EnterUpsidenounBtn;

