import classes from './UndergroundPage.module.css';

export const glasses = (
    <svg className={classes.parentColor} width="388" height="144" viewBox="0 0 388 144" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M264 0H244V24H264V0Z" fill="#AE6C0A" />
      <path d="M336 0H288V24H336V0Z" fill="#AE6C0A" />
      <path d="M264 24H244V48H264V24Z" fill="#AE6C0A" />
      <path d="M336 24H288V48H336V24Z" fill="#AE6C0A" />
      <path d="M244 48H48V72H244V48Z" fill="#068940" />
      <path d="M264 48H244V72H264V48Z" fill="#AE6C0A" />
      <path d="M336 48H288V72H336V48Z" fill="#AE6C0A" />
      <path d="M264 72H244V100H264V72Z" fill="#AE6C0A" />
      <path d="M336 72H288V100H336V72Z" fill="#AE6C0A" />
      <path d="M216 0H72V24H216V0Z" className={classes.color} />
      <path d="M388 0H244V24H388V0Z" className={classes.color} />
      <path d="M100 24H72V48H100V24Z" className={classes.color} />
      <path d="M144 24H100V48H144V24Z" fill="white" />
      <path d="M192 24H144V48H192V24Z" fill="black" />
      <path d="M216 24H192V48H216V24Z" className={classes.color} />
      <path d="M264 24H244V48H264V24Z" className={classes.color} />
      <path d="M316 24H264V48H316V24Z" fill="white" />
      <path d="M364 24H316V48H364V24Z" fill="black" />
      <path d="M388 24H364V48H388V24Z" className={classes.color} />
      <path d="M100 48H0V72H100V48Z" className={classes.color} />
      <path d="M144 48H100V72H144V48Z" fill="white" />
      <path d="M192 48H144V72H192V48Z" fill="black" />
      <path d="M264 48H192V72H264V48Z" className={classes.color} />
      <path d="M316 48H264V72H316V48Z" fill="white" />
      <path d="M364 48H316V72H364V48Z" fill="black" />
      <path d="M388 48H364V72H388V48Z" className={classes.color} />
      <path d="M24 72H0V100H24V72Z" className={classes.color} />
      <path d="M100 72H72V100H100V72Z" className={classes.color} />
      <path d="M144 72H100V100H144V72Z" fill="white" />
      <path d="M192 72H144V100H192V72Z" fill="black" />
      <path d="M216 72H192V100H216V72Z" className={classes.color} />
      <path d="M264 72H244V100H264V72Z" className={classes.color} />
      <path d="M316 72H264V100H316V72Z" fill="white" />
      <path d="M364 72H316V100H364V72Z" fill="black" />
      <path d="M388 72H364V100H388V72Z" className={classes.color} />
      <path d="M24 100H0V120H24V100Z" className={classes.color} />
      <path d="M100 100H72V120H100V100Z" className={classes.color} />
      <path d="M144 100H100V120H144V100Z" fill="white" />
      <path d="M192 100H144V120H192V100Z" fill="black" />
      <path d="M216 100H192V120H216V100Z" className={classes.color} />
      <path d="M264 100H244V120H264V100Z" className={classes.color} />
      <path d="M316 100H264V120H316V100Z" fill="white" />
      <path d="M364 100H316V120H364V100Z" fill="black" />
      <path d="M388 100H364V120H388V100Z" className={classes.color} />
      <path d="M216 120H72V144H216V120Z" className={classes.color} />
      <path d="M388 120H244V144H388V120Z" className={classes.color} />
    </svg>
  );
