import classes from './Manifesto.module.css';
import React from "react";
import Section from '../../layout/Section';
import { Col, Row } from 'react-bootstrap';
import Link from '../../components/Link';
import Sunouns from "../../components/Sunouns";

const Manifesto: React.FC = props => {

    return (
        <Section fullWidth={true} >
            <Col lg={{ span: 8, offset: 2 }}>
                <Row className={classes.headerRow}>
                    <h1>Manifesto</h1>
                </Row>
                <br />
            </Col>
            <Col lg={{ span: 9, offset: 2 }}>
                <div className={classes.bullets}>

                    <h2>Privacy is a fundamental right and a public good</h2>
                    <p>
                        Our mission is to fund open source public goods for privacy.
                    </p>
                    <h2>Pseudo-anonymity enables an open society</h2>
                    <p>
                        One of the fundamental human rights and a requirement for self-expression is privacy. Separation 
                        of identities across our social selves, real selves, and our earning self, provides us the foundation
                        for privacy as well as the right to 
                        access. 
                        <p />
                        <span style={{color: 'var(--brand-sunrise-blue)'}}>Balaji Srinivasan</span> describes this as a pseudonymous 
                        economy <Link text='in this tweet.' url='https://twitter.com/balajis/status/1578653505703006208' leavesPage={true} />
                    </p>
                    <h2>The need for pseudonymous identities</h2>
                    <p>
                        To participate in a pseudonymous economy we need pseudonymous identities. We believe that creating a pseudonymous 
                        identity should be easy and accessible to everyone, but today it’s not. If you choose 
                        to bid on a <Sunouns text='nouns' fontSize='20px' /> we strongly encourage you to try and 
                        remain as pseudonymous as possible to experience just how difficult it is, especially on Etherum.
                        That is why we created <Sunouns text='nouns' fontSize='20px' />, to gather like-minded people in building this future.
                    </p>
                    <h2>zunsunz was banned from Twitter</h2>
                    <p>
                        We need censorship resistant networks and Web2 companies are failing to provide 
                        this. <a href="https://twitter.com/zunsunz" target="_blank" rel="noopener noreferrer" className={classes.zunsunz}>zunsunz</a>, the 
                        pseudonym of the primary developer involved in creating <Sunouns text='nouns' fontSize='20px' />, created a Twitter profile to discuss 
                        pseudonymity and to be the voice of the project. Shortly after tweeting about building tools for pseudonymity, Twitter 
                        banned <a href="https://twitter.com/zunsunz" target="_blank" rel="noopener noreferrer" className={classes.zunsunz}>zunsunz</a>. To 
                        this day, Twitter has not clarified which rule was violated and has not responded to attempts to restore the account.
                    </p>
                    <h2>Pseudonymity means engaging with the real world</h2>
                    <p>
                        It is important for pseudonymous identities to have the means to interact with the real world, including 
                        businesses. We believe tools are needed to bridge online pseudonymous world and the business
                        world. We created <span style={{color: 'var(--brand-sunrise-blue)'}}>Sunounomous Labs, LLC</span> to 
                        serve the legal needs of the <span style={{color: 'var(--brand-sunrise-blue)'}}>Underground</span>, the 
                        decentralized pseudonymous society of <Sunouns text='nouns' fontSize='20px' />.
                    </p>
                </div>
            </Col>
        </Section>
    );;
};

export default Manifesto;