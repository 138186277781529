import { Col, Row } from 'react-bootstrap';
import { BigNumber } from 'ethers';
import AuctionActivityWrapper from '../AuctionActivityWrapper';
import AuctionNavigation from '../AuctionNavigation';
import AuctionActivityNounTitle from '../AuctionActivityNounTitle';
import AuctionActivityDateHeadline from '../AuctionActivityDateHeadline';
import AuctionTitleAndNavWrapper from '../AuctionTitleAndNavWrapper';
import nounContentClasses from './NounderNounContent.module.css';
import auctionBidClasses from '../AuctionActivity/BidHistory.module.css';
import auctionActivityClasses from '../AuctionActivity/AuctionActivity.module.css';
import CurrentBid, { BID_N_A } from '../CurrentBid';
import Winner from '../Winner';
import Link from '../Link'
import { Trans } from '@lingui/macro';

const zunsunzBirthDate =  1002018121; // time_t for 2001-10-02

const NounderNounContent: React.FC<{
  mintTimestamp: BigNumber;
  nounId: BigNumber;
  isFirstAuction: boolean;
  isLastAuction: boolean;
  onPrevAuctionClick: () => void;
  onNextAuctionClick: () => void;
}> = props => {
  const {
    mintTimestamp,
    nounId,
    isFirstAuction,
    isLastAuction,
    onPrevAuctionClick,
    onNextAuctionClick,
  } = props;

  const zunsunz_eth = (
    <>
      <Trans>
        <span style={{color: 'var(--brand-sunrise-blue)', fontWeight: 'bold',fontSize: '20px'}}>su</span>
        <span style={{color: 'var(--brand-dark-red)', fontWeight: 'bold', fontSize: '20px'}}>noun</span>
        <span style={{color: 'var(--brand-dark-red)', fontWeight: 'bold'}}> 5 </span>
        is special among the sunouns, the only member of the sunouns that can go between the Underground and the upside.
        For this reason, it was minted to
      </Trans>{' '}<a href="https://twitter.com/zunsunz" target="_blank" rel="noopener noreferrer" className={nounContentClasses.sunouns}>
      zunsunz</a>{' '}
      <Trans>creator of the sunouns and spokesnoun for the Underground, a sunounomous society.</Trans>
    </>
  )

  const noundao_eth = (
    <>
    <Trans>The</Trans>
    {" "}
    <Link url={'https://nouns.wtf/'} leavesPage={true}
      text={<Trans>Nouns DAO</Trans>}
    />
    {' '}
    <Trans>
      is a groundbreaking experimental attempt to improve the formation of on-chain avatar communities. As a thank you 
      and to make the NounsDAO a member of the Underground, <a href="https://twitter.com/zunsunz" target="_blank" rel="noopener noreferrer" className={nounContentClasses.sunouns}>
      zunsunz</a> has choose to send the first 
      <span style={{color: 'var(--brand-sunrise-blue)', fontWeight: 'bold'}}>su</span><span style={{color: 'var(--brand-dark-red)', fontWeight: 'bold'}}>noun</span> to the Nouns DAO.
    </Trans>
    </>
  )

  return (
    <AuctionActivityWrapper>
      <div className={auctionActivityClasses.informationRow}>
        <Row className={auctionActivityClasses.activityRow}>
          <AuctionTitleAndNavWrapper>
            <AuctionNavigation
              isFirstAuction={isFirstAuction}
              isLastAuction={isLastAuction}
              onNextAuctionClick={onNextAuctionClick}
              onPrevAuctionClick={onPrevAuctionClick}
            />
            <AuctionActivityDateHeadline startTime={ (nounId.eq(5)) ? BigNumber.from(zunsunzBirthDate) : mintTimestamp} />
          </AuctionTitleAndNavWrapper>
          <Col lg={12}>
            <AuctionActivityNounTitle nounId={nounId} />
          </Col>
        </Row>
        <Row className={auctionActivityClasses.activityRow}>
          <Col lg={4} className={auctionActivityClasses.currentBidCol}>
            <CurrentBid currentBid={BID_N_A} auctionEnded={true} />
          </Col>
          <Col
            lg={5}
            className={`${auctionActivityClasses.currentBidCol} ${nounContentClasses.currentBidCol} ${auctionActivityClasses.auctionTimerCol}`}
          >
            <div className={auctionActivityClasses.section}>
              <Winner winner={''} isNounders={nounId.eq(0) || nounId.eq(5)} isZunsunz={nounId.eq(5)}/>
            </div>
          </Col>
        </Row>
      </div>
      <Row className={auctionActivityClasses.activityRow}>
        <Col lg={12}>
          <ul className={auctionBidClasses.bidCollection}>
            <li
              className={ nounContentClasses.text}
            >
              {nounId.eq(0) ? noundao_eth : zunsunz_eth }
            </li>
          </ul>
        </Col>
      </Row>
    </AuctionActivityWrapper>
  );
};
export default NounderNounContent;