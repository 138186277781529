import React from "react";
import Section from '../../layout/Section';
import { Col, Row } from 'react-bootstrap';
import Documentation from '../../components/Documentation';
import Sunouns from "../../components/Sunouns";
import classes from './UndergroundPage.module.css';
import { glasses } from "./glasses";

const PrelaunchPage: React.FC = props => {

  return (
    <Section fullWidth={true} >
      <Col lg={{ span: 8, offset: 2 }}>
        <div style={{fontFamily: 'Londrina Solid'}}>
          <Sunouns text='nouns' fontSize='90px' />
        </div>
        {glasses}
        <Row className={classes.headerRow}>
          <span>Born in the Underground, derived from the <a href="https://nouns.wtf/" target="_blank" rel="noopener noreferrer">Nouns DAO</a></span>
        </Row>
        <Row style={{ marginBottom: '0rem' }}>
        </Row>
        <Row className={classes.mainContent}>
          <p>
            <Sunouns text='nouns' fontSize='20px' /> are an on-chain NFT project with the purpose of improving how 
            pseudonymous (<span style={{color: "var(--brand-sunrise-blue)"}}>su</span><span style={{color: "var(--brand-dark-red)"}}>noun</span>omous) societies
            function. <Sunouns text='nouns' fontSize='20px' /> are a derivative of the <a href="https://nouns.wtf/" target="_blank" rel="noopener noreferrer">Nouns DAO</a>, an NFT community born and living 100% on-chain.
            The art is generated by calling the Nouns contract and rendering its assets upside down.
          </p>
          <p>
            One <span style={{color: "var(--brand-sunrise-blue)"}}>su</span><span style={{color: "var(--brand-dark-red)"}}>noun</span> will be auctioned 
            every <span style={{color: "var(--brand-sunrise-blue)"}}>4</span> hours <span style={{color: "var(--brand-sunrise-blue)"}}>2</span> minutes, 
            forever. Once the auction is over and settled the winner will receive 
            their <span style={{color: "var(--brand-sunrise-blue)"}}>su</span><span style={{color: "var(--brand-dark-red)"}}>noun</span> and 
            the next auction will start immediately.
            The profits from each <span style={{color: "var(--brand-sunrise-blue)"}}>su</span><span style={{color: "var(--brand-dark-red)"}}>noun</span> will be 
            sent to the Treasury. The Treasury will be used to build tools used to 
            maintain <span style={{color: "var(--brand-sunrise-blue)"}}>su</span><span style={{color: "var(--brand-dark-red)"}}>noun</span>omous  
            identities.
          </p>
          <p>
            The Underground is a decentralized pseudonymous society of <Sunouns text='nouns' fontSize='20px' /> with the purpose of
            improving the tools needed to maintain pseudonymous identities. The Underground and
            the <Sunouns text='nouns' fontSize='20px' /> project were created
            by <a href="https://github.com/zunsunz" target="_blank" rel="noopener noreferrer" className={classes.sunouns}>zunsunz</a>, a
            builder who wants improve our ability to form private decentralized groups and to facilitate how they organize and communicate.
          </p>
          <p>
            If you want to be part of our <span style={{color: "var(--brand-sunrise-blue)"}}>su</span><span style={{color: "var(--brand-dark-red)"}}>noun</span>omous society,
            follow <a href="https://twitter.com/sunounsdao" target="_blank" rel="noopener noreferrer" className={classes.sunouns}>sunounsdao</a> on Twitter and be ready for when 
            the <Sunouns text='nouns' fontSize='20px' /> rise from The Underground.
          </p>
        </Row>
        <Row>
          <div className={classes.comingSoon}><Sunouns text='nouns' fontSize='90px' />  coming soon...</div>
        </Row>
      </Col>
      <Documentation />
    </Section>
  );;
};
export default PrelaunchPage;
