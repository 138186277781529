import classes from './Sunouns.module.css';

const Sunouns: React.FC<{ text: string, fontSize: string; }> = props => {
  const { text, fontSize } = props;

  return (
    <span className={classes.flip}>
      <span style={{color: 'var(--brand-sunrise-blue)', fontSize: fontSize }}>su</span>
      <span style={{color: 'var(--brand-dark-red)', fontSize: fontSize}}>{text}</span>
    </span>
  );
};
export default Sunouns;