import React from 'react';
import classes from './EnterActivitySunounTitle.module.css';

const EnterActivityUpsidenounTitle: React.FC<{rotate: boolean}> = (props) => {
  const { rotate } = props;

  const upnoun = <><span>unou</span><span style={{color: 'var(--brand-sunrise-blue)'}}>ns</span></>;

  return (
  <div className={classes.wrapper} >
    one{' '}
    <span className={rotate ? classes.rotate : classes.unrotate}>
      {rotate ? upnoun : 'noun'}
    </span>
    <br/>
    every{' '}
    <span className={rotate ? classes.rotate : classes.unrotate}>
      <span style={{fontFamily: 'forward', fontSize: '50px' }}>
        <span style={rotate ? {color: 'var(--brand-sunrise-blue)'} : {}}>2</span>4<span style={rotate ? {color: 'var(--brand-sunrise-blue)'} : {}}>h</span>
      </span>
    </span>
  </div>
  );
};

export default EnterActivityUpsidenounTitle;
