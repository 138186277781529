import React from "react";
import { Col, Row } from 'react-bootstrap';
import Section from '../../layout/Section';
import Sunouns from "../../components/Sunouns";
import PrelaunchPage from "./prelaunch";
import classes from './UndergroundPage.module.css';
import { glasses } from "./glasses";

const UndergroundPage: React.FC<{preLaunch: boolean }> = props => {
  const { preLaunch } = props

  return preLaunch ? <PrelaunchPage /> : <UndergroundContent />
}

const UndergroundContent: React.FC = props => {

  return (
    <Section fullWidth={true} className={classes.undergroundPage}>
      <Col lg={{ span: 6, offset: 3 }}>
        <Row className={classes.headerRow}>
          <span>The Underground</span>
          <h1>Welcome to The Underground</h1>
        </Row>
        `<br />
        {glasses}

        <br />

        <Row className={classes.mainContent}>
          <p>
            The Underground is a decentralized pseudonymous society of <Sunouns text='nouns' fontSize='20px' /> with the purpose of
            improving the tools needed to maintain pseudonymous identities. The Underground and
            the <Sunouns text='nouns' fontSize='20px' /> project were created
            by <a href="https://twitter.com/zunsunz" target="_blank" rel="noopener noreferrer" className={classes.sunouns}>zunsunz</a>, a
            builder who wants improve our ability to form private decentralized groups and to facilitate how they organize and communicate.
          </p>
          <p>
            More info about the pseudonymous economy.
          </p>
          <p>
            So join us, and be part of the revolution.
          </p>


        </Row>
      </Col>
    </Section>
  );;
};
export default UndergroundPage;
