import React from 'react';
import { Col } from 'react-bootstrap';

import classes from './NounInfoCard.module.css';

import NounInfoRowBirthday from '../NounInfoRowBirthday';
import NounInfoRowHolder from '../NounInfoRowHolder';
import NounInfoRowButton from '../NounInfoRowButton';
import { useAppSelector } from '../../hooks';

import config from '../../config';
import { buildEtherscanAddressLink } from '../../utils/etherscan';
import { Trans } from '@lingui/macro';

interface NounInfoCardProps {
  nounId: number;
  bidHistoryOnClickHandler: () => void;
}

const NounInfoCard: React.FC<NounInfoCardProps> = props => {
  const { nounId, bidHistoryOnClickHandler } = props;

  const etherscanBaseURL = buildEtherscanAddressLink(config.addresses.nounsToken);

  const etherscanButtonClickHandler = () => window.open(`${etherscanBaseURL}/${nounId}`, '_blank');

  const lastAuctionNounId = useAppSelector(state => state.onDisplayAuction.lastAuctionNounId);

  const BidsIcon = (
    <svg width="20" height="20" viewBox="0 0 20 20" className="color" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 3C6.73478 3 6.48043 3.10536 6.29289 3.29289C6.10536 3.48043 6 3.73478 6 4C6 4.26522 6.10536 4.51957 6.29289 4.70711C6.48043 4.89464 6.73478 5 7 5H13C13.2652 5 13.5196 4.89464 13.7071 4.70711C13.8946 4.51957 14 4.26522 14 4C14 3.73478 13.8946 3.48043 13.7071 3.29289C13.5196 3.10536 13.2652 3 13 3H7ZM4 7C4 6.73478 4.10536 6.48043 4.29289 6.29289C4.48043 6.10536 4.73478 6 5 6H15C15.2652 6 15.5196 6.10536 15.7071 6.29289C15.8946 6.48043 16 6.73478 16 7C16 7.26522 15.8946 7.51957 15.7071 7.70711C15.5196 7.89464 15.2652 8 15 8H5C4.73478 8 4.48043 7.89464 4.29289 7.70711C4.10536 7.51957 4 7.26522 4 7ZM2 11C2 10.4696 2.21071 9.96086 2.58579 9.58579C2.96086 9.21071 3.46957 9 4 9H16C16.5304 9 17.0391 9.21071 17.4142 9.58579C17.7893 9.96086 18 10.4696 18 11V15C18 15.5304 17.7893 16.0391 17.4142 16.4142C17.0391 16.7893 16.5304 17 16 17H4C3.46957 17 2.96086 16.7893 2.58579 16.4142C2.21071 16.0391 2 15.5304 2 15V11Z" fill="currentColor" />
    </svg>
  );

  const AddressIcon = ( 
    <svg width="20" height="20" className="color" viewBox="0 0 20 20"  xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.11141 3.18218C6.78148 3.12868 7.41759 2.86512 7.92915 2.42904C8.50694 1.93694 9.24108 1.66667 10 1.66667C10.759 1.66667 11.4931 1.93694 12.0709 2.42904C12.5824 2.86512 13.2186 3.12868 13.8886 3.18218C14.6453 3.24265 15.3558 3.57069 15.8926 4.10747C16.4293 4.64425 16.7574 5.35468 16.8179 6.1114C16.871 6.7812 17.1345 7.41767 17.571 7.92914C18.0631 8.50692 18.3334 9.24106 18.3334 10C18.3334 10.759 18.0631 11.4931 17.571 12.0709C17.1349 12.5824 16.8714 13.2185 16.8179 13.8886C16.7574 14.6453 16.4293 15.3558 15.8926 15.8925C15.3558 16.4293 14.6453 16.7574 13.8886 16.8178C13.2186 16.8713 12.5824 17.1349 12.0709 17.571C11.4931 18.0631 10.759 18.3333 10 18.3333C9.24108 18.3333 8.50694 18.0631 7.92915 17.571C7.41759 17.1349 6.78148 16.8713 6.11141 16.8178C5.3547 16.7574 4.64427 16.4293 4.10749 15.8925C3.5707 15.3558 3.24267 14.6453 3.18219 13.8886C3.12869 13.2185 2.86514 12.5824 2.42905 12.0709C1.93695 11.4931 1.66669 10.759 1.66669 10C1.66669 9.24106 1.93695 8.50692 2.42905 7.92914C2.86514 7.41758 3.12869 6.78147 3.18219 6.1114C3.24267 5.35468 3.5707 4.64425 4.10749 4.10747C4.64427 3.57069 5.3547 3.24265 6.11141 3.18218ZM13.8615 8.65311C14.0513 8.45664 14.1563 8.19351 14.1539 7.92039C14.1515 7.64726 14.042 7.38599 13.8489 7.19285C13.6557 6.99972 13.3945 6.89016 13.1213 6.88779C12.8482 6.88542 12.5851 6.99041 12.3886 7.18017L8.95834 10.6104L7.61144 9.26354C7.41497 9.07378 7.15184 8.96879 6.87871 8.97116C6.60559 8.97354 6.34432 9.08309 6.15118 9.27622C5.95805 9.46936 5.84849 9.73063 5.84612 10.0038C5.84375 10.2769 5.94874 10.54 6.1385 10.7365L8.22187 12.8198C8.41721 13.0151 8.68212 13.1248 8.95834 13.1248C9.23455 13.1248 9.49946 13.0151 9.69481 12.8198L13.8615 8.65311Z" fill="currentColor"/>
    </svg>
  );

  return (
    <>
      <Col lg={12} className={classes.nounInfoRow}>
        <NounInfoRowBirthday nounId={nounId} />
      </Col>
      <Col lg={12} className={classes.nounInfoRow}>
        <NounInfoRowHolder nounId={nounId} />
      </Col>
      <Col lg={12} className={classes.nounInfoRow}>
        <NounInfoRowButton
          image={BidsIcon}
          btnText={lastAuctionNounId === nounId ? <Trans>Bids</Trans> : <Trans>Bid history</Trans>}
          onClickHandler={bidHistoryOnClickHandler}
        />
        <NounInfoRowButton
          image={AddressIcon}
          btnText={<Trans>Etherscan</Trans>}
          onClickHandler={etherscanButtonClickHandler}
        />
      </Col>
    </>
  );
};

export default NounInfoCard;
