import { BigNumber } from 'ethers';
import classes from './AuctionActivityNounTitle.module.css';

const AuctionActivityNounTitle: React.FC<{ nounId: BigNumber }> = props => {
  const { nounId } = props;
  return (
    <div className={classes.wrapper}>
      <h1 className={nounId.eq(5) ? classes.flip : ''}>
        <span className={classes.su}>su</span><span className={classes.noun}>noun </span><span className={classes.number}>{nounId.toString()}</span>
      </h1>
    </div>
  );
};
export default AuctionActivityNounTitle;
